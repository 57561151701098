import Vue from 'vue'
import Router from 'vue-router'
const isDevelopment = process.env.NODE_ENV === 'development'
const modeType = isDevelopment?'hash':'history'
Vue.use(Router)

export default new Router({
  mode: modeType,
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'homePage',
      meta: {
        title: '工作台',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/homePage'], resolve)
    },
    {
      path: '/treatPage',
      name: 'treatPage',
      meta: {
        title: '理疗工作台',
        level: '1',
        keepAlive: true // 需要缓存
      },
      component: resolve => require(['@/view/treatPage'], resolve)
    },
    {
      path: '/treatDetail',
      name: 'treatDetail',
      meta: {
        title: '理疗详情',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/treatDetail'], resolve)
    },
    {
      path: '/treatExec',
      name: 'treatExec',
      meta: {
        title: '分诊/完成',
        level: '1',
        keepAlive: false // 不需要缓存
      },
      component: resolve => require(['@/view/treatExec'], resolve)
    },
    {
      path: '/historyPage',
      name: 'historyPage',
      meta: {
        title: '理疗历史',
        level: '1',
        keepAlive: true // 不需要缓存
      },
      component: resolve => require(['@/view/historyPage'], resolve)
    },
    {
      path: '/medicalPage',
      name: 'medicalPage',
      meta: {
        title: '患者病历',
        level: '1',
        keepAlive: true // 不需要缓存
      },
      component: resolve => require(['@/view/medicalPage'], resolve)
    },
    {
      path: '/medicalDetail',
      name: 'medicalDetail',
      meta: {
        title: '病历详情',
        level: '1',
        keepAlive: false // 不需要缓存
      },
      component: resolve => require(['@/view/medicalDetail'], resolve)
    },
    {
      path: '/my',
      name: 'my',
      meta: {
        title: '我的',
        level: '1',
        keepAlive: false // 不需要缓存
      },
      component: resolve => require(['@/view/my'], resolve)
    },
    {
      path: '/seePage',
      name: 'seePage',
      meta: {
        title: '医生工作台',
        level: '1',
        keepAlive: true // 需要缓存
      },
      component: resolve => require(['@/view/seePage'], resolve)
    },
    {
      path: '/changeClinic',
      name: 'changeClinic',
      meta: {
        title: '切换诊所',
        level: '1',
        keepAlive: false // 不需要缓存
      },
      component: resolve => require(['@/view/changeClinic'], resolve)
    },
    {
      path: '/bindLogin',
      name: 'bindLogin',
      meta: {
        title: '绑定账号',
        level: '1',
        keepAlive: false // 不需要缓存
      },
      component: resolve => require(['@/view/bindLogin'], resolve)
    },
    {
      path: '/takePicture',
      name: 'takePicture',
      meta: {
        title: '拍照上传',
        level: '1',
        keepAlive: false // 不需要缓存
      },
      component: resolve => require(['@/view/takePicture'], resolve)
    },
    {
      path: '/checkPage',
      name: 'checkPage',
      meta: {
        title: '检查历史',
        level: '1',
        keepAlive: true // 不需要缓存
      },
      component: resolve => require(['@/view/checkPage'], resolve)
    },
    {
      path: '/matchAcupoint',
      name: 'matchAcupoint',
      meta: {
        title: '配穴操作',
        level: '1',
        keepAlive: false // 不需要缓存
      },
      component: resolve => require(['@/view/matchAcupoint'], resolve)
    },
    {
      path: '/habitusDetail',
      name: 'habitusDetail',
      meta: {
        title: '五行体质报告',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/habitusDetail'], resolve)
    },
    {
      path: '/infraredDetail',
      name: 'infraredDetail',
      meta: {
        title: '红外报告',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/infraredDetail'], resolve)
    },
    {
      path: '/signature',
      name: 'signature',
      meta: {
        title: '签名',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/signature'], resolve)
    },
    {
      path: '/triage',
      name: 'triage',
      meta: {
        title: '免预约分诊',
        level: '1',
        keepAlive: true // 需要缓存
      },
      component: resolve => require(['@/view/triage'], resolve)
    },
    {
      path: '/assignTreat',
      name: 'assignTreat',
      meta: {
        title: '确定分诊',
        level: '1',
        keepAlive: true// 需要缓存
      },
      component: resolve => require(['@/view/assignTreat'], resolve)
    },
    {
      path: '/otherPay',
      name: 'otherPay',
      meta: {
        title: '他人抵扣',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/otherPay'], resolve)
    },
    {
      path: '/patientList',
      name: 'patientList',
      meta: {
        title: '患者列表',
        level: '1',
        keepAlive: true // 需要缓存
      },
      component: resolve => require(['@/view/patientList'], resolve)
    },
    {
      path: '/patientDetail',
      name: 'patientDetail',
      meta: {
        title: '患者详情',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/patientDetail'], resolve)
    },
    {
      path: '/reservation',
      name: 'reservation',
      meta: {
        title: '门诊预约',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/reservation'], resolve)
    },
    {
      path: '/coupon',
      name: 'coupon',
      meta: {
        title: '优惠券',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/coupon'], resolve)
    },
    {
      path: '/card',
      name: 'card',
      meta: {
        title: '卡包',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/card'], resolve)
    },
    {
      path: '/addUser',
      name: 'addUser',
      meta: {
        title: '注册会员',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/addUser'], resolve)
    },
    {
      path: '/chooseUser',
      name: 'chooseUser',
      meta: {
        title: '选择会员',
        level: '1',
        keepAlive: false // 需要缓存
      },
      component: resolve => require(['@/view/chooseUser'], resolve)
    },
    {
      path: '/bookingManagement',
      name: 'bookingManagement',
      meta: {
        title: '预约管理',
        level: '1',
        keepAlive: true 
      },
      component: resolve => require(['@/view/bookingManagement'], resolve)
    },
    {
      path: '/doctorMain',
      name: 'doctorMain',
      meta: {
        title: '医生主页',
        level: '1',
        keepAlive: false 
      },
      component: resolve => require(['@/view/doctorMain'], resolve)
    },
    {
      path: '/appointment',
      name: 'appointment',
      meta: {
        title: '预约',
        level: '1',
        keepAlive: false 
      },
      component: resolve => require(['@/view/appointment'], resolve)
    },
    {
      path: '/registrationDesk',
      name: 'registrationDesk',
      meta: {
        title: '报到分诊台',
        level: '1',
        keepAlive: true 
      },
      component: resolve => require(['@/view/registrationDesk'], resolve)
    },
    {
      path: '/appointmentDetail',
      name: '/appointmentDetail',
      meta: {
        title: '预约详情',
        level: '1',
        keepAlive: false 
      },
      component: resolve => require(['@/view/appointmentDetail'], resolve)
    },
  ],
  
})

