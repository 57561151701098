import request from '@/utils/request'
import interfaceHandle from '@/utils/index'

export function wxtreatstatistics (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatstatistics', data, true)
    })
}

export function wxtreatqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatqry', data, true)
    })
}

export function wxtreatdetail (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatdetail', data, true)
    })
}

export function wxtreatopr (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatopr', data, true)
    })
}

export function wxdoctorqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxdoctorqry', data, true)
    })
}

export function wxroomqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxroomqry', data, true)
    })
}

export function wxopenhistoryqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxopenhistoryqry', data, true)
    })
}

export function wxtreathistoryqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreathistoryqry', data, true)
    })
}

export function wxsurplusqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxsurplusqry', data, true)
    })
}

export function wxjsapiauth (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxjsapiauth', data, true)
    })
}

export function wxmedicalhistoryqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxmedicalhistoryqry', data, true)
    })
}

export function wxmedicaldetailqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxmedicaldetailqry', data, true)
    })
}

export function wxseestatistics (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxseestatistics', data, true)
    })
}

export function wxseeqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxseeqry', data, true)
    })
}

export function wxsendpreinfo (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxsendpreinfo', data, true)
    })
}

export function wxdeleteattach (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxdeleteattach', data, true)
    })
}


export function wxattachqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxattachqry', data, true)
    })
}

export function wxcheckhistoryqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxcheckhistoryqry', data, true)
    })
}

export function wxinfraredreportqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxinfraredreportqry', data, true)
    })
}


export function wxhabitusreportqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxhabitusreportqry', data, true)
    })
}

export function wxacupointqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxacupointqry', data, true)
    })
}

export function wxaddacupoint (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxaddacupoint', data, true)
    })
}


export function wxacupointopr (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxacupointopr', data, true)
    })
}

export function wxsavesignature (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxsavesignature', data, true)
    })
}

export function waitsignaturelist (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('waitsignaturelist', data, true)
    })
}
export function wxprojectlistqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxprojectlistqry', data, true)
    })
}
export function wxtriageprojectqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriageprojectqry', data, true)
    })
}
export function wxtreattriageopr (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreattriageopr', data, true)
    })
}
export function curuserqrytreattriage (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('curuserqrytreattriage', data, true)
    })
}
export function wxtriagedoctorroomqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagedoctorroomqry', data, true)
    })
}
export function wxtreatuserphonecaptchano (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatuserphonecaptchano', data, true)
    })
}
export function wxtriagecustomerqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecustomerqry', data, true)
    })
}
// 
export function wxtriagecurdayappointmentlistqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecurdayappointmentlistqry', data, true)
    })
}
export function wxtriageappointmentlistqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriageappointmentlistqry', data, true)
    })
}
export function wxtriagecouponnum (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecouponnum', data, true)
    })
}
export function wxtriagecouponqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecouponqry', data, true)
    })
}
export function wxtriagecardnum (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecardnum', data, true)
    })
}
export function wxtriagecardqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecardqry', data, true)
    })
}
export function wxtriagedictionarydataqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagedictionarydataqry', data, true)
    })
}
export function wxtreatregisteredmember (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatregisteredmember', data, true)
    })
}
export function wxtriageeditcardreceive (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriageeditcardreceive', data, true)
    })
}
export function wxtriagememberinfoqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagememberinfoqry', data, true)
    })
}
export function wxtriageworkbenchqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriageworkbenchqry', data, true)
    })
}
export function wxappointmentplaninfoqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentplaninfoqry', data, true)
    })
  }
  export function wxappointmentdatebydoctor (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentdatebydoctor', data, true)
    })
  }
  export function   wxappointmentplandetail (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentplandetail', data, true)
    })
  }
  export function   wxconfirmappointment (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxconfirmappointment', data, true)
    })
  }
  export function   wxappointmentcheckininfoqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentcheckininfoqry', data, true)
    })
  }
  export function wxappointmentopr (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentopr', data, true)
    })
  }
  export function wxappointmenttypebydoctor (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmenttypebydoctor', data, true)
    })
  }
  export function wxappointmentdetail (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentdetail', data, true)
    })
  }
  export function wxappointmentplanbydoctor (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentplanbydoctor', data, true)
    })
  }
  export function wxsubmitappointmentinfo (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxsubmitappointmentinfo', data, true)
    })
  }
  export function  wxnoappointmentcustomerinfoqry  (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxnoappointmentcustomerinfoqry', data, true)
    })
  }
// 诊所查询  
  export function  wxqryclinic(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqryclinic', data, true)
    })
  }
  
// 修改预约信息  
export function  wxmodifyappointmentinfo(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxmodifyappointmentinfo', data, true)
    })
  }